<template>
    <div>
        <b-card>
            <b-media no-body>
                <avatar b-avatar-size="65" variant="light-warning" badge="success" />

                <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputEl.$el.click()"
                        disabled
                    >
                        Upload
                    </b-button>
                    <b-form-file
                        ref="refInputEl"
                        v-model="profileFile"
                        accept=".jpg, .png"
                        :hidden="true"
                        plain
                        @input="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        disabled
                    >
                        Reset
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>Allowed JPG or PNG. Max size of 800kB</b-card-text>
                </b-media-body>
            </b-media>
            <!--/ media -->

            <!-- form -->
            <b-form class="mt-2">
                <b-row>
                    <b-col sm="6">
                        <b-form-group
                            label="Name"
                            label-for="account-name"
                        >
                            <b-form-input
                                v-model="userData.name"
                                name="name"
                                placeholder="Name"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            label="E-mail"
                            label-for="account-e-mail"
                        >
                            <b-form-input
                                v-model="userData.email"
                                name="email"
                                placeholder="Email"
                            />

                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            class="mt-2 mr-1"
                            :disabled="savingProfile"
                            @click="submitProfile"
                        >
                            <b-spinner v-if="savingProfile" small />
                            <span v-if="!savingProfile">Save changes</span>
                        </b-button>
                        <b-button
                            v-b-modal.confirmModal
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="flat-danger"
                            class="mt-2"
                        >
                            Delete account
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
        <modal-confirm-vuexy
            text="Are you sure you want to delete your account?"
            title="Delete account?"
            :ok-function="deleteAccount"
        />
    </div>
</template>

<script>
import {
    BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaBody, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line no-unused-vars
import State from '@/store'
import Axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalConfirmVuexy from '../Modals/ModalConfirmVuexy.vue'
import Avatar from '../Misc/Avatar.vue'

export default {
    components: {
        BButton,
        BForm,
        BFormFile,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BCardText,
        BMedia,
        BMediaBody,
        BSpinner,
        Avatar,
        ModalConfirmVuexy,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            userData: {
                name: '',
                email: '',
            },
            profileFile: null,
            data: null,
            savingProfile: false,
            profileSaved: false,
            profileErrors: [],
        }
    },
    computed: {
        user() {
            return State.getters.user
        },
    },
    created() {
        this.fillUserData()
    },
    methods: {
        fillUserData() {
            this.userData.name = this.user.name
            this.userData.email = this.user.email
        },
        submitProfile() {
            this.savingProfile = true
            this.profileSaved = false
            this.profileErrors = []
            new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}profile/edit`, data: this.userData, method: 'PUT' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }).then(() => this.$store.dispatch('fetchUser')).then(() => {
                this.savingProfile = false
                this.profileSaved = true

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success!',
                        icon: 'CheckIcon',
                        text: 'Your new profile settings were successfully saved.',
                        variant: 'primary',
                    },
                })
            }).catch(err => {
                for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                    const errorGroup = err.response.data.errors[errorGroupKey]
                    this.profileErrors = this.profileErrors.concat(errorGroup)
                }
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error!',
                        icon: 'XIcon',
                        text: 'Your new profile settings cannot be saved at the time.',
                        variant: 'danger',
                    },
                })
                this.savingProfile = false
            })
        },
        deleteAccount() {
            this.deleteModalLoading = true
            new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}profile/delete`, data: this.userData, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }).then(() => {
                this.deleteModalLoading = false
                this.deleteModalVisible = false
                this.$store.dispatch('logout')
                this.$router.push('login')
            })
        },
    },
    setup() {
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

        return {
            refInputEl,
            previewEl,
            inputImageRenderer,
        }
    },
}
</script>
