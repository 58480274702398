<template>
    <b-card
        title="Actions under Fulfillin list"
    >
        <app-timeline>
            <app-timeline-item variant="success">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>Shipment status change</h6>
                    <small class="text-muted">2 min ago</small>
                </div>
                <p>Shipment has been marked as completed.</p>
                <p class="mb-0">
                    <feather-icon
                        icon="TruckIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="align-bottom">FBA15CLTJN70</span>
                </p>
            </app-timeline-item>
            <app-timeline-item variant="info">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>Created a shipment</h6>
                    <small class="text-muted">40 min ago</small>
                </div>
                <p>New shipment has been created.</p>
                <p class="mb-0">
                    <feather-icon
                        icon="PackageIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="align-bottom">FBA15CLTJN70</span>
                </p>
            </app-timeline-item>
            <app-timeline-item variant="warning">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>Group changed</h6>
                    <small class="text-muted">45 min ago</small>
                </div>
                <p>Your user group was changed to <b>Fulfillment</b></p>
                <b-media>
                    <template #aside>
                        <avatar b-avatar-size="45" variant="light-warning" badge="success" full-user-name="J V" />
                    </template>
                    <h6>JV</h6>
                    <p class="mb-0">
                        Super Admin at CG
                    </p>
                </b-media>
            </app-timeline-item>
            <app-timeline-item>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>New project</h6>
                    <small class="text-muted">2 days ago</small>
                </div>
                <p>You have been added to a new project - <b>CG</b>.</p>
                <b-media>
                    <template #aside>
                        <avatar b-avatar-size="45" variant="light-warning" badge="success" full-user-name="J V" />
                    </template>
                    <h6>JV</h6>
                    <p class="mb-0">
                        Super Admin at CG
                    </p>
                </b-media>
            </app-timeline-item>
        </app-timeline>
    </b-card>
</template>

<script>
import {
    BCard, BMedia,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import Avatar from '../Misc/Avatar.vue'

export default {
    components: {
        BCard,
        BMedia,
        Avatar,
        AppTimeline,
        AppTimelineItem,
    },
    directives: {
        Ripple,
    },
}
</script>
