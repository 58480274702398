<template>
    <b-card
        no-body
    >
        <b-card-body>
            <b-card-title>Permissions</b-card-title>
            <b-card-sub-title>Permission according to access to Fulfillin's modules</b-card-sub-title>
        </b-card-body>
        <b-table
            striped
            responsive
            :items="permissionsData"
            class="mb-0"
        >

            <template #cell(module)="data">
                {{ data.value }}
            </template>
            <template #cell()="data">
                <b-form-checkbox
                    disabled
                    :checked="data.value"
                />
            </template>
        </b-table>
    </b-card>
</template>

<script>
import {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
    components: {
        BCard,
        BTable,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    computed: {
        permissions() {
            return store.getters.permissionList
        },
    },
    setup() {
        const permissionsData = []
        const permArray = store.getters.permissionList
        Object.values(permArray).forEach(value => {
            let formattedModule = value.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\\{\\}\\[\]\\\\//]/gi, ' ')
            formattedModule = formattedModule.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
            permissionsData.push({
                module: formattedModule,
                read: (value.currentLevel >= 1 || (value.max_level === 0 && value.currentLevel === 0)),
                write: (value.currentLevel >= 2 || (value.max_level === 0 && value.currentLevel === 0)),
                create: (value.currentLevel >= 2 || (value.max_level === 0 && value.currentLevel === 0)),
                delete: (value.currentLevel >= 2 || (value.max_level === 0 && value.currentLevel === 0)),
            })
        })
        return {
            permissionsData,
        }
    },
}
</script>
