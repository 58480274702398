<template>
    <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
    >
        <b-tab active>
            <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"
                />
                <span class="font-weight-bold">General</span>
            </template>

            <account-setting-general :user-data="user" />
        </b-tab>
        <b-tab>
            <template #title>
                <feather-icon
                    icon="LayersIcon"
                    size="18"
                    class="mr-50"
                />
                <span class="font-weight-bold">Permissions</span>
            </template>

            <account-setting-permissions />
        </b-tab>
        <b-tab disabled>
            <template #title>
                <feather-icon
                    icon="InfoIcon"
                    size="18"
                    class="mr-50"
                />
                <span class="font-weight-bold">Information</span>
            </template>

            <account-setting-information />
        </b-tab>
        <b-tab disabled>
            <template #title>
                <feather-icon
                    icon="BellIcon"
                    size="18"
                    class="mr-50"
                />
                <span class="font-weight-bold">Notifications</span>
            </template>
        </b-tab>
    </b-tabs>
</template>
<style>

</style>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import State from '@/store'
import AccountSettingGeneral from '../../components/Profile/AccountSettingGeneral.vue'
import AccountSettingPermissions from '../../components/Profile/AccountSettingPermissions.vue'
import AccountSettingInformation from '../../components/Profile/AccountSettingInformation.vue'

export default {
    name: 'settings.profile',
    components: {
        BTabs,
        BTab,
        AccountSettingGeneral,
        AccountSettingPermissions,
        AccountSettingInformation,
    },
    data() {
        return {
            currentTab: 'profile',
        }
    },
    computed: {
        user() {
            return State.getters.user
        },
    },
}
</script>
