<template>
    <b-avatar
        :size="bAvatarSize"
        :variant="variant"
        class="badge-minimal"
        :badge-variant="badge"
    >
        <span
            v-if="!user.fullName"
            :style="`font-weight: bold; font-size: ${fontSize}px;`"
        >
            {{ avatarTwoLetters }}
        </span>
        <feather-icon
            v-if="user.fullName"
            icon="UserIcon"
            size="30"
        />
    </b-avatar>
</template>

<script>
import State from '@/store'
import {
    BAvatar,
} from 'bootstrap-vue'

export default {
    components: {
        BAvatar,
    },
    props: {
        bAvatarSize: {
            type: String,
            required: true,
            default: '20',
        },
        variant: {
            type: String,
            default: 'light-warning',
        },
        badge: {
            type: String,
            default: 'success',
        },
        fontSize: {
            type: String,
            default: '20',
        },
        fullUserName: {
            type: String,
            default: State.getters.user.name.toUpperCase(),
        },
    },
    computed: {
        user() {
            if (State.getters.user) {
                return State.getters.user
            }

            return { name: '', email: '' }
        },
        avatarTwoLetters() {
            const userName = this.fullUserName
            const userNameWordCount = userName.trim().split(/\s+/).length
            if (userNameWordCount === 1 && userName.length <= 2) return userName

            return userName
                .match(/(^(?:\S+\s?){1,3})/g)
                .join()
                .split(/\s/)
                .map(s => s.charAt(0))
                .join('')
        },
    },
}
</script>
